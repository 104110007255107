var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"me-page"},[_c('div',{staticClass:"me-page-topbar text-secondary fill-width position-fixed top-0 z-index-over-above"},[_c('div',{staticClass:"me-page-topbar-wrapper flex justify-between align-end fill-width"},[_vm._m(0),_c('div',{staticClass:"font-sm pa-5",on:{"click":_vm.checkBulk}},[_vm._v(" "+_vm._s(_vm.ifBulkCheck ? "取消管理" : "批量管理")+" ")])])]),_c('div',{staticClass:"me-page-list flex justify-center pt-55"},[_c('nut-scroller',{staticClass:"me-page-list-scroll",attrs:{"type":"vertical"},on:{"loadMore":_vm.loadVideos,"pulldown":_vm.refreshVideos}},[(_vm.videos.length)?_c('div',{staticClass:"nut-vert-list favorite-list",attrs:{"slot":"list"},slot:"list"},[_vm._l((_vm.videos),function(video,index){return [_c('div',{key:`video-${index}`,staticClass:"position-relative",on:{"click":function($event){return _vm.playThis(video.id, index)}}},[_c('transition',{attrs:{"name":"fade"}},[(_vm.ifBulkCheck)?_c('nut-checkbox',{ref:"favCheck",refInFor:true,staticClass:"position-absolute right-0 video-checkbox z-index-above",attrs:{"size":"small","checked":_vm.checkedVideos[index]},on:{"update:checked":function($event){return _vm.$set(_vm.checkedVideos, index, $event)},"change":function($event){return _vm.checkThis(video.id)}},model:{value:(_vm.checkedVideos[index]),callback:function ($$v) {_vm.$set(_vm.checkedVideos, index, $$v)},expression:"checkedVideos[index]"}}):_vm._e()],1),_c('nut-row',{directives:[{name:"touch-scale",rawName:"v-touch-scale"}],class:[
                'nut-vert-list-item',
                index !== 0 ? 'mt-15' : '',
                'bg-white',
                'radius-card'
              ]},[_c('nut-col',{attrs:{"span":8}},[_c('div',{staticClass:"height-100",style:({
                    backgroundImage: `url(${video.thumbnail})`,
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover'
                  })})]),_c('nut-col',{staticClass:"height-100",attrs:{"span":16}},[_c('nut-row',{staticClass:"text-left pa-10 text-primary video-list-item-height position-relative"},[_c('nut-col',{staticClass:"font-bold",attrs:{"span":24}},[_vm._v(_vm._s(video.title))]),_c('nut-col',{staticClass:"position-absolute bottom-3 left-0 pl-10 pr-3",attrs:{"span":24}},[_c('div',{staticClass:"flex justify-between align-center"},[_c('span',{staticClass:"font-medium font-bold"},[_vm._v(_vm._s(video.length))]),_c('nut-icon',{attrs:{"type":"self","size":"36px","url":require('@/assets/icons/play_btn.svg')}})],1)])],1)],1)],1)],1)]})],2):_c('div',{staticClass:"d-flex align-center justify-center"},[_vm._v(" 空空如也... ")])])],1),_c('transition',{attrs:{"name":"bounce"}},[(_vm.ifBulkCheck)?_c('div',{staticClass:"position-fixed bottom-0 z-index-above flex justify-between align-center bulk-check-option-wrapper"},[_c('div',{staticClass:"control-text-btn-wrapper"},[_c('nut-button',{attrs:{"small":"","type":"light"},on:{"click":_vm.removeFromFavorites}},[_vm._v("移除")]),_c('nut-button',{attrs:{"small":"","type":"light"},on:{"click":_vm.cancelCheckAll}},[_vm._v("取消")])],1),_c('div',{staticClass:"mr-20"},[_c('nut-checkbox',{staticClass:"video-checkbox",attrs:{"size":"small"},on:{"change":_vm.checkAll},model:{value:(_vm.ifCheckAll),callback:function ($$v) {_vm.ifCheckAll=$$v},expression:"ifCheckAll"}})],1)]):_vm._e()])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"font-lg flex align-center"},[_c('img',{attrs:{"src":require("../assets/images/favorite_img.png"),"width":"50px","alt":""}}),_c('span',{staticClass:"my-favorites-style"},[_vm._v("My Favorites")])])
}]

export { render, staticRenderFns }