<template>
  <div class="me-page">
    <div
      class="me-page-topbar text-secondary fill-width position-fixed top-0 z-index-over-above"
    >
      <div
        class="me-page-topbar-wrapper flex justify-between align-end fill-width"
      >
        <div class="font-lg flex align-center">
          <img src="../assets/images/favorite_img.png" width="50px" alt="" />
          <span class="my-favorites-style">My Favorites</span>
        </div>
        <div class="font-sm pa-5" @click="checkBulk">
          {{ ifBulkCheck ? "取消管理" : "批量管理" }}
        </div>
      </div>
    </div>
    <div class="me-page-list flex justify-center pt-55">
      <nut-scroller
        class="me-page-list-scroll"
        type="vertical"
        @loadMore="loadVideos"
        @pulldown="refreshVideos"
      >
        <div
          v-if="videos.length"
          slot="list"
          class="nut-vert-list favorite-list"
        >
          <template v-for="(video, index) in videos">
            <div
              :key="`video-${index}`"
              class="position-relative"
              @click="playThis(video.id, index)"
            >
              <transition name="fade">
                <nut-checkbox
                  v-if="ifBulkCheck"
                  ref="favCheck"
                  v-model="checkedVideos[index]"
                  class="position-absolute right-0 video-checkbox z-index-above"
                  size="small"
                  :checked.sync="checkedVideos[index]"
                  @change="checkThis(video.id)"
                ></nut-checkbox>
              </transition>
              <nut-row
                :class="[
                  'nut-vert-list-item',
                  index !== 0 ? 'mt-15' : '',
                  'bg-white',
                  'radius-card'
                ]"
                v-touch-scale
              >
                <nut-col :span="8">
                  <div
                    class="height-100"
                    :style="{
                      backgroundImage: `url(${video.thumbnail})`,
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover'
                    }"
                  ></div>
                </nut-col>
                <nut-col :span="16" class="height-100">
                  <nut-row
                    class="text-left pa-10 text-primary video-list-item-height position-relative"
                  >
                    <nut-col :span="24" class="font-bold">{{
                      video.title
                    }}</nut-col>
                    <nut-col
                      :span="24"
                      class="position-absolute bottom-3 left-0 pl-10 pr-3"
                    >
                      <div class="flex justify-between align-center">
                        <span class="font-medium font-bold">{{
                          video.length
                        }}</span>
                        <nut-icon
                          type="self"
                          size="36px"
                          :url="require('@/assets/icons/play_btn.svg')"
                        ></nut-icon>
                      </div>
                    </nut-col>
                  </nut-row>
                </nut-col>
              </nut-row>
            </div>
          </template>
        </div>
        <div v-else class="d-flex align-center justify-center">
          空空如也...
        </div>
      </nut-scroller>
    </div>
    <transition name="bounce">
      <div
        v-if="ifBulkCheck"
        class="position-fixed bottom-0 z-index-above flex justify-between align-center bulk-check-option-wrapper"
      >
        <div class="control-text-btn-wrapper">
          <nut-button small type="light" @click="removeFromFavorites"
            >移除</nut-button
          >
          <nut-button small type="light" @click="cancelCheckAll"
            >取消</nut-button
          >
        </div>
        <div class="mr-20">
          <nut-checkbox
            v-model="ifCheckAll"
            class="video-checkbox"
            size="small"
            @change="checkAll"
          ></nut-checkbox>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import VideoService from "@/services/video.service";

export default {
  name: "Me",
  title() {
    return `${this.$t("me.pageTitle")}`;
  },
  data() {
    return {
      videos: [],
      ifBulkCheck: false,
      checkedVideos: [],
      checkedVideoIds: [],
      checked: false,
      ifCheckAll: false
    };
  },

  created() {
    this.getFavorites();
  },

  methods: {
    getFavorites() {
      VideoService.getFavorites().then(res => {
        this.videos = res.data;
      });
    },

    removeFromFavorites() {
      if (this.checkedVideoIds.length) {
        VideoService.removeFromFavorites(this.checkedVideoIds).then(() => {
          this.initFavorites();
        });
      } else {
        this.$toast.warn("还未选择视频");
        return false;
      }
    },

    initFavorites() {
      this.checkedVideoIds.map(id => {
        const index = this.videos.findIndex(video => {
          return video.id === id;
        });

        this.videos.splice(index, 1);
      });
      this.checkedVideoIds = [];
    },

    checkThis(vid) {
      if (this.checkedVideoIds.indexOf(vid) === -1) {
        this.checkedVideoIds.push(vid);
      } else {
        this.checkedVideoIds.splice(this.checkedVideoIds.indexOf(vid), 1);
      }
    },

    checkBulk() {
      this.ifBulkCheck = !this.ifBulkCheck;
      if (!this.ifBulkCheck) {
        this.initCheckStatus();
      }
    },

    cancelCheckAll() {
      this.ifBulkCheck = false;
      this.initCheckStatus();
    },

    initCheckStatus() {
      this.checkedVideoIds = [];
      this.checkedVideos = [];
      this.ifCheckAll = false;
    },

    checkAll() {
      const favChecks = this.$refs["favCheck"];

      favChecks.map((favCheck, index) => {
        if (this.ifCheckAll) {
          if (!favCheck.isChecked) {
            favCheck.isChecked = true;
            if (this.checkedVideoIds.indexOf(this.videos[index].id) === -1) {
              this.checkedVideoIds.push(this.videos[index].id);
            }
          }
        } else {
          favCheck.isChecked = false;
          this.checkedVideoIds = [];
        }
      });
    },

    playThis(id, i) {
      if (this.ifBulkCheck) {
        const favChecks = this.$refs["favCheck"];

        favChecks.map((favCheck, index) => {
          if (i === index) {
            favCheck.isChecked = !favCheck.isChecked;
            this.checkThis(id);
          }
        });
      } else {
        this.$router.push({ name: "Video", params: { id } });
      }
    },

    loadVideos() {},

    refreshVideos() {}
  }
};
</script>

<style lang="scss" scoped>
.me-page {
  .my-favorites-style {
    font-family: GreatVibes, serif;
  }
  width: 100%;
  background-color: rgb(142, 49, 60);
  &-topbar {
    background-color: #ffffff;
    border-radius: 0 0 10px 10px;
    &-wrapper {
      width: calc(100% - 20px);
      padding: 10px;
    }
  }
  &-list {
    width: 100%;
    margin-top: 20px;
    &-scroll {
      width: 93%;
    }
  }
  .video-list-item-height {
    height: calc(100% - 20px);
  }
  .bulk-check-option-wrapper {
    height: 60px;
    width: 100%;
    background-color: #ffffff;
    border-radius: 15px 15px 0 0;
  }
  .control-text-btn-wrapper {
    margin-left: 16px;
    button {
      border-color: transparent;
      color: #8e313c;
    }
  }
  .favorite-list {
    margin-bottom: 30px;
  }
}
</style>
